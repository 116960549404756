<template>
  <div>
    <div class="chat msg_history overflow-auto">
      <div v-for="(x, i) in conversation" :key="i">
        <div v-if="x.who == 'chat'" class="msg shadow-none">
          <b-button
            v-if="x.type == 'button'"
            size="sm"
            variant="outline-secondary"
            class="rounded"
            @click="!x.href ? chatButton(x.text) : null"
            :href="x.href"
            target="_blank"
          >
            {{ x.text }} <span v-if="x.href"><i class="fas fa-external-link-alt"></i></span>
          </b-button>
          <a v-if="x.type == 'link'" :href="x.href" target="_blank" class="text-secondary">
            {{ x.text || x.href }} <i class="fas fa-external-link-alt"></i>
          </a>

          <div v-if="x.type == 'img'" class="justify-content-center">
            <div v-if="typeof imageSources[x.src] != 'string'">
              <b-spinner type="grow" label="Spinning" variant="secondary"></b-spinner>
            </div>

            <div v-else>
              <!-- render img in a only if href is specified -->
              <a v-if="x.href" :href="x.href" target="_blank">
                <img
                  :src="imageSources[x.src]"
                  :alt="x.alt || ''"
                  :key="imageSources[x.src]"
                  @load="scrollDown()"
                  class="img-fluid img-thumbnail"
                />
              </a>

              <img
                v-else
                :src="imageSources[x.src]"
                :alt="x.alt || ''"
                :key="imageSources[x.src]"
                @load="scrollDown()"
                class="img-fluid img-thumbnail"
              />
            </div>
          </div>
          <div v-if="interactive && x.type == 'attachment'" class="justify-content-center">
            <div class="d-flex flex-row">
              <b-form-file
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                plain
                :multiple="x.multipload != 'False'"
                :capture="x.capture"
                :accept="x.acceptedFormats"
                @change="saveFileUploadEvent($event, x.index)"
              ></b-form-file>
              <b-button size="sm" variant="primary" @click="uploadAttachment($event, x.index)">Upload</b-button>
            </div>
          </div>
          <div v-if="x.type == 'attachment_submitted'">
            <div class="msg user"> <i class="fa fa-paperclip" /> Attachment: {{ x.files.join(', ') }} </div>
          </div>
          <div v-if="x.type == 'chat_selectbox'">
            <div class="d-flex flex-row" style="max-width: 32em">
              <b-form-select
                :v-model="selectedEntities[x.index]"
                @input="
                  (e) => {
                    return $set(selectedEntities, x.index, e)
                  }
                "
                class="p-1 w-auto"
                :disabled="!interactive"
                style="min-width: 15em"
              >
                <b-form-select-option v-for="v in selectboxEntities[x.entity]" :key="'entity-value-' + v" :value="v">{{
                  v
                }}</b-form-select-option>
              </b-form-select>
              <b-button
                variant="primary"
                class="ml-2 my-1"
                size="sm"
                @click="selectedEntities[x.index] && chatButton(selectedEntities[x.index])"
                >{{ x.btnLabel }}</b-button
              >
            </div>
          </div>
          <div v-if="x.type == 'chat_datepicker'">
            <div class="d-flex flex-row" style="max-width: 32em; overflow: visible">
              <b-form-datepicker
                v-model="selectedDate[x.index]"
                placeholder="Choose a date"
                class="p-1 w-auto"
                :disabled="!interactive"
                style="min-width: 15em"
                size="sm"
                data-container="body"
                dropright="true"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              ></b-form-datepicker>

              <b-button
                variant="primary"
                class="ml-2 my-1"
                size="sm"
                @click="selectedDate[x.index] && chatButton(selectedDate[x.index])"
                >{{ x.btnLabel }}</b-button
              >
            </div>
          </div>
        </div>
        <div v-if="x.who == 'user'" class="msg" :class="x.who" v-html="x.text"></div>
        <div v-if="x.who == 'bot'" class="msg" :class="x.who">
          <span v-html="x.text"> </span>
          <span v-if="readUtter" class="play-utter" @click="readUtter(x.text)"><i class="fas fa-play ml-2"></i></span>
        </div>

        <div v-if="x.who == 'user'" class="confidence mt-0 mr-3">
          <small>
            <span v-b-tooltip.hover :title="x.alternatives_text">
              {{ x.intent }}
              <span v-if="x.confidence">{{ x.confidence | round(2) }}</span>
            </span>

            <span v-if="x.alternatives && logLevelToInt('fine') == level"
              ><br />Alternatives: [{{ x.alternatives.join(', ') }}]</span
            >
          </small>
        </div>
        <div v-if="x.who == 'log'" class="log" :class="'log-' + x.level">{{ x.level.toUpperCase() }}: {{ x.text }}</div>
        <div v-if="x.who == 'reaction'" class="ml-3 mt-0 reaction-name">
          <span>{{ x.text }} </span></div
        >
      </div>
      <div ref="bottomOfTheConversation" style="clear: both"></div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'Conversation',
  props: {
    tracker: Array,
    level: String,
    interactive: { type: Boolean, default: false },
    entitiesLoader: Function,
    readUtter: Function,
  },
  computed: {
    ...mapFields('rest', ['entities']),
    conversation() {
      let conversation = []

      if (!this.tracker) {
        return []
      }

      let selfLogLevel = this.logLevelToInt(this.level)
      let chatAttachmentIndex = 0
      trackerLoop: for (const [index, c] of this.tracker.entries()) {
        var l = {}
        if (c.value == 'utter') {
          l.who = 'bot'
          l.text = c.args[0]
        }

        if (c.type == 'intent') {
          l.who = 'user'
          l.text = c.args.text
          l.confidence = c.args.confidence
          l.intent = c.value
          if (c.args.alternatives) {
            l.alternatives = c.args.alternatives.map((x) => x.name + ': ' + x.confidence.toFixed(2))
            l.alternatives_text = 'Alternatives: [' + l.alternatives.join(', ') + ']'
          }
        }

        if (c.type == 'log' && this.logLevelToInt(c.args.level) >= selfLogLevel) {
          l.who = 'log'
          l.text = c.value
          l.level = c.args.level
        }

        if (c.type == 'reaction') {
          l.who = 'reaction'
          l.text = c.value
        }

        if (['reaction', 'action'].includes(c.type) && this.logLevelToInt('info') >= selfLogLevel) {
          if (c.value != 'utter') {
            l.who = 'log'
            l.text = c.type + ' ' + c.value
            l.level = 'info'

            if (c.type == 'action' && Array.isArray(c.args)) {
              l.text += ' (' + c.args.join(', ') + ')'
            }
          }
        }

        if (c.type == 'action' && c.value.startsWith('chat_')) {
          if (c.value == 'chat_button') {
            l.who = 'chat'
            l.type = 'button'
            l.text = c.args[0]
            l.href = c.args[1]
          }

          if (c.value == 'chat_link') {
            l.who = 'chat'
            l.type = 'link'
            l.href = c.args[0]
            if (c.args.length > 1) {
              l.text = c.args[1]
            }
          }

          if (c.value == 'chat_img') {
            l.who = 'chat'
            l.type = 'img'

            this.getImg(c.args[0])

            // [l.src, l.alt, l.href] = c.args
            l.src = c.args[0]
            if (c.args.length > 1) {
              l.alt = c.args[1]
            }
            if (c.args.length > 2) {
              l.href = c.args[2]
            }
          }
          if (c.value == 'chat_attachment') {
            for (const x of this.tracker) {
              if (x.type == 'action' && x.value == 'chat_attachment_submitted' && x.args[1] == chatAttachmentIndex) {
                continue trackerLoop // do not render chat_attachment upload form as it was already uploaded
              }
            }

            l.who = 'chat'
            l.type = 'attachment'
            l.index = chatAttachmentIndex
            l.acceptedFormats = c.args[1]
            l.multipload = c.args[3]
            l.capture = c.args[4]

            chatAttachmentIndex++
          }
          if (c.value == 'chat_attachment_submitted') {
            l.who = 'chat'
            l.type = 'attachment_submitted'
            l.files = c.args[0]
          }
          if (c.value == 'chat_selectbox') {
            l.who = 'chat'
            l.type = 'chat_selectbox'
            l.entity = c.args[0]
            l.btnLabel = c.args[1] || 'Select'
            l.index = index

            if (this.interactive) {
              this.loadEntity(l.entity)
            } else {
              this.$set(this.selectboxEntities, l.entity, ['Entity: ' + l.entity])
            }
          }
          if (c.value == 'chat_datepicker') {
            l.who = 'chat'
            l.type = 'chat_datepicker'
            l.btnLabel = c.args[0] || 'Select'
            l.index = index
          }
        }

        if (l.who) {
          conversation.push(l)
        }
      }

      return conversation
    },
  },
  data() {
    return {
      imageSources: {},
      fileUploadEvents: {},
      selectedEntities: {},
      selectboxEntities: {},
      selectedDate: {},
    }
  },
  methods: {
    logLevelToInt(level) {
      var levels = {
        error: 50,
        warn: 40,
        info: 30,
        ok: 20,
        fine: 10,
      }

      var i = levels[level]
      if (!i) {
        return 40 // default level WARN
      }
      return i
    },
    chatButton(text) {
      this.$emit('button', text)
    },
    uploadAttachment(e, trackerIndex) {
      if (!this.fileUploadEvents[trackerIndex]) {
        this.$notify({
          group: 'app',
          type: 'warn',
          title: 'No Attachment provided',
          text: 'Please choose file first.',
        })
        return
      }
      this.$emit('upload', this.fileUploadEvents[trackerIndex], trackerIndex)
    },
    saveFileUploadEvent(e, index) {
      this.fileUploadEvents[index] = e
    },
    getImg(file) {
      if (this.imageSources[file]) {
        return
      }
      this.imageSources[file] = true // temp val

      this.axios
        .get('v1/file', {
          responseType: 'blob',
          params: {
            file: file,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
          this.imageSources[file] = url

          this.$forceUpdate()
        })
    },
    scrollDown() {
      this.$nextTick(() => {
        if (this.interactive) {
          this.$refs['bottomOfTheConversation'].scrollIntoView({ behavior: 'smooth' })
        }
      })
    },
    loadEntity(entity) {
      this.entitiesLoader(entity).then((x) => {
        this.$set(this.selectboxEntities, entity, x)
      })
    },
  },
}
</script>

<style src="./Conversation.scss" lang="scss" />
