import Vue from 'vue'
import Vuex from 'vuex'

import layout from './layout'
import pam from './pam'
import metadata from './metadata'

Vue.use(Vuex)

import Vapi from 'vuex-rest-api'

import { getField, updateField } from 'vuex-map-fields'
let rest = new Vapi({
  state: {
    intents: [],
    synonyms: [],
    entities: [],
    entitiesOriginal: {},
    voices: [],
    reactions: [],
    reactionsFlow: {},
    actions: [],
    storage: [],
    users: [],
    usersLogs: [],
    companies: [],
    calls: [],
    callDetail: {},
    settings: {},
    dirty: {},
    chatOpened: false,
    testChat: [],
    tests: [],
    projects: [],
    deployments: [],
    languages: [],
    reactionsScopes: [],
    reactionsNotes: [],
    runAllTestsTrigger: false, //@TODO refactor, use global events: Vue.this.$events
    unclassifiedIntents: [],
    stats: [],
    availableLogLevels: ['fine', 'info', 'warn', 'error'],
    permissions: [],
    permissionsResources: {},
    usersProjects: {},
    labels: {},
    auditDeleted: {},
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
  },
  queryParams: true,
})

var actionsPathsMap = {}

let originalAdd = rest.add
rest.add = function (options) {
  actionsPathsMap['rest/' + options.action] = options
  return originalAdd.apply(this, arguments)
}

rest = rest
  .put({
    action: 'putNegatedTemplate',
    path: '/v1/intents/template-negation',
  })
  .post({
    action: 'postStripStopwords',
    path: '/v1/intents/strip-stopwords',
  })
  .get({
    action: 'getEvaluation',
    property: 'evaluation',
    path: '/v1/intents/evaluation',
  })
  .get({
    action: 'getIntents',
    property: 'intents',
    path: '/v1/intents',
  })
  .get({
    action: 'getIntentsExport',
    path: '/v1/intents',
    property: 'fileToDownload',
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/octet-stream',
    },
    requestConfig: {
      responseType: 'blob',
    },
  })
  .put({
    action: 'updateIntents',
    path: '/v1/intents',
  })
  .put({
    action: 'insertIntent',
    path: '/v1/intent/-1',
  })
  .put({
    action: 'updateIntent',
    path: ({ id }) => `/v1/intent/${id}`,
  })
  .delete({
    action: 'deleteIntent',
    path: ({ id }) => `/v1/intent/${id}`,
  })
  .put({
    action: 'insertIntentTemplate',
    path: ({ id }) => `/v1/intent/${id}/template`,
  })
  .delete({
    action: 'deleteIntentTemplate',
    path: ({ id }) => `/v1/intent/${id}/template`,
  })
  .get({
    action: 'getSynonyms',
    property: 'synonyms',
    path: '/v1/synonyms',
  })
  .put({
    action: 'updateSynonyms',
    path: '/v1/synonyms',
  })
  .get({
    action: 'getEntities',
    property: 'entities',
    path: '/v1/entities',
  })
  .get({
    action: 'getEmailReports',
    property: 'emailReports',
    path: '/v1/email-reports',
  })
  .delete({
    action: 'deleteEmailReport',
    path: ({ id }) => `/v1/email-reports/${id}`,
  })
  .put({
    action: 'insertEmailReport',
    path: '/v1/email-reports/-1',
  })
  .put({
    action: 'insertEntity',
    path: '/v1/entities/-1',
  })
  .put({
    action: 'updateEntity',
    path: ({ id }) => `/v1/entities/${id}`,
  })
  .delete({
    action: 'deleteEntity',
    path: ({ id }) => `/v1/entities/${id}`,
  })
  .get({
    action: 'reloadWorker',
    path: '/v1/worker/reload',
  })
  .get({
    action: 'getUnclassifiedIntents',
    property: 'unclassifiedIntents',
    path: '/v1/intents/unclassified',
  })
  .post({
    action: 'updateIntentSetClassified',
    path: ({ id }) => `/v1/intents/${id}/mark-classified`,
  })
  .get({
    action: 'getSettings',
    property: 'settings',
    path: '/v1/settings',
  })
  .put({
    action: 'updateSettings',
    path: '/v1/settings',
  })
  .get({
    action: 'getVoices',
    property: 'voices',
    path: '/v1/voices',
  })
  .get({
    action: 'getConversations',
    property: 'calls',
    path: '/v1/conversations',
  })
  .get({
    action: 'getConversationsExport',
    path: '/v1/conversations',
    property: 'fileToDownload',
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/octet-stream',
    },
    requestConfig: {
      responseType: 'blob',
    },
  })
  .get({
    action: 'getConversation',
    property: 'callDetail',
    path: ({ id }) => `/v1/conversations/${id}`,
  })
  .get({
    action: 'getStorage',
    property: 'storage',
    path: '/v1/storage',
  })
  .get({
    action: 'getFile',
    path: '/v1/file',
  })
  .get({
    action: 'getReactions',
    property: 'reactions',
    path: '/v1/reactions',
  })
  .put({
    action: 'insertReaction',
    path: '/v1/reactions/-1',
  })
  .get({
    action: 'getReactionsFlow',
    property: 'reactionsFlow',
    path: '/v1/reactions/flow',
  })
  .delete({
    action: 'deleteReaction',
    path: ({ id }) => `/v1/reactions/${id}`,
  })
  .put({
    action: 'updateReaction',
    path: ({ id }) => `/v1/reactions/${id}`,
  })
  .put({
    action: 'updateReactions',
    path: '/v1/reactions',
  })
  .put({
    action: 'updateGraphLayout',
    path: '/v1/reactions/layout',
  })
  .get({
    action: 'getActions',
    property: 'actions',
    path: '/v1/actions',
  })
  .put({
    action: 'updateActions',
    path: '/v1/actions',
  })
  .put({
    action: 'updateAction',
    path: ({ id }) => `/v1/actions/${id}`,
  })
  .delete({
    action: 'deleteAction',
    path: ({ id }) => `/v1/actions/${id}`,
  })
  .post({
    action: 'postChatMessage',
    // property: 'testChat', //response should append into this
    path: '/v1/chat',
  })
  .post({
    action: 'postChatTimeout',
    // property: 'testChat', //response should append into this
    path: '/v1/chat/timeout',
  })
  .post({
    action: 'initChat',
    property: 'testChat',
    path: '/v1/chat/init',
  })
  .get({
    action: 'getTests',
    property: 'tests',
    path: '/v1/tests',
  })
  .put({
    action: 'insertTest',
    path: '/v1/tests/-1',
  })
  .put({
    action: 'updateTest',
    path: ({ id }) => `/v1/tests/${id}`,
  })
  .delete({
    action: 'deleteTest',
    path: ({ id }) => `/v1/tests/${id}`,
  })
  .get({
    action: 'runTest',
    path: ({ id }) => `/v1/run/test/${id}`,
  })
  .get({
    action: 'runDeploy',
    path: '/v1/run/deploy',
  })
  .get({
    action: 'runTrain',
    path: '/v1/run/train',
  })
  .get({
    action: 'getProjects',
    property: 'projects',
    path: '/v1/projects',
  })
  .post({
    action: 'createProject',
    path: '/v1/projects',
  })
  .post({
    action: 'cloneScript',
    path: '/v1/projects/clone',
  })
  .get({
    action: 'getReactionsScopes',
    property: 'reactionsScopes',
    path: '/v1/reactions/scopes',
  })
  .put({
    action: 'updateReactionsScopes',
    path: '/v1/reactions/scopes',
  })
  .put({
    action: 'insertReactionScope',
    path: '/v1/reactions/scopes/-1',
  })
  .get({
    action: 'getReactionsNotes',
    property: 'reactionsNotes',
    path: '/v1/reactions/notes',
  })
  .put({
    action: 'updateReactionNote',
    path: ({ id }) => `/v1/reactions/notes/${id}`,
  })
  .put({
    action: 'insertReactionNote',
    path: '/v1/reactions/notes/-1',
  })
  .delete({
    action: 'deleteReactionNote',
    path: ({ id }) => `/v1/reactions/notes/${id}`,
  })
  .put({
    action: 'updateConversationNote',
    path: ({ id }) => `/v1/conversations/notes/${id}`,
  })
  .put({
    action: 'insertConversationNote',
    path: '/v1/conversations/notes/-1',
  })
  .delete({
    action: 'deleteConversationNote',
    path: ({ id }) => `/v1/conversations/notes/${id}`,
  })
  .delete({
    action: 'deleteReactionScope',
    path: ({ id }) => `/v1/reactions/scope/${id}`,
  })
  .get({
    action: 'getUsers',
    property: 'users',
    path: '/v1/users',
  })
  .put({
    action: 'updateUser',
    path: ({ id }) => `/v1/users/${id}`,
  })
  .post({
    action: 'validatePassword',
    path: '/v1/users/validate-passwd',
  })
  .put({
    action: 'userChangePasswd',
    path: '/v1/users/change-my-passwd',
  })
  .delete({
    action: 'deleteUser',
    path: ({ id }) => `/v1/users/${id}`,
  })
  .post({
    action: 'resetPassword',
    path: ({ id }) => `/v1/users/reset/${id}`,
  })
  .get({
    action: 'getUsersLogs',
    path: '/v1/users-logs',
    property: 'usersLogs',
  })
  .get({
    action: 'exportUsersLogs',
    path: '/v1/users-logs',
    property: 'fileToDownload',
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/octet-stream',
    },
    requestConfig: {
      responseType: 'blob',
    },
  })
  .get({
    action: 'getCompanies',
    path: '/v1/companies',
    property: 'companies',
  })
  .get({
    action: 'getDeployments',
    path: '/v1/deployments',
    property: 'deployments',
  })
  .put({
    action: 'updateCompany',
    path: ({ id }) => `/v1/companies/${id}`,
  })
  .delete({
    action: 'deleteCompany',
    path: ({ id }) => `/v1/companies/${id}`,
  })
  .get({
    action: 'getLanguages',
    path: '/v1/languages',
    property: 'languages',
  })
  .get({
    action: 'getStats',
    property: 'stats',
    path: '/v1/stats',
  })
  .get({
    action: 'getProjectToken',
    property: 'projectToken',
    path: '/v1/project/api-token',
  })
  .put({
    action: 'putProjectToken',
    property: 'projectToken',
    path: '/v1/project/api-token',
  })
  .get({
    action: 'getRoles',
    property: 'roles',
    path: '/v1/roles',
  })
  .put({
    action: 'insertRole',
    path: '/v1/roles/-1',
  })
  .put({
    action: 'updateRole',
    path: ({ id }) => `/v1/roles/${id}`,
  })
  .delete({
    action: 'deleteRole',
    path: ({ id }) => `/v1/roles/${id}`,
  })
  .put({
    action: 'updateRolesPermissions',
    path: '/v1/roles/permissions',
  })
  .get({
    action: 'getPermissions',
    property: 'permissions',
    path: '/v1/permissions',
  })
  .get({
    action: 'getPermissionsResources',
    property: 'permissionsResources',
    path: '/v1/permissions/resources',
  })
  .get({
    action: 'getUsersProjects',
    property: 'usersProjects',
    path: '/v1/users/projects',
  })
  .put({
    action: 'updateUsersProjects',
    path: '/v1/users/projects',
  })
  .get({
    action: 'getOutboundCampaigns',
    property: 'outboundCampaigns',
    path: '/v1/outbound/campaigns',
  })
  .put({
    action: 'insertOutboundCampaign',
    path: '/v1/outbound/campaigns/-1',
  })
  .put({
    action: 'updateOutboundCampaign',
    path: ({ id }) => `/v1/outbound/campaigns/${id}`,
  })
  .delete({
    action: 'deleteOutboundCampaign',
    path: ({ id }) => `/v1/outbound/campaigns/${id}`,
  })
  .get({
    action: 'exportOutboundCampaignReport',
    path: ({ id }) => `/v1/outbound/campaigns/${id}/report`,
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/octet-stream',
    },
    requestConfig: {
      responseType: 'blob',
    },
  })
  .post({
    action: 'uploadOutboundCampaignData',
    path: ({ id }) => `/v1/outbound/campaigns/${id}/upload`,
  })
  .get({
    action: 'getLabels',
    property: 'labels',
    path: '/v1/labels',
  })
  .put({
    action: 'insertLabel',
    path: '/v1/labels/-1',
  })
  .put({
    action: 'updateLabel',
    path: ({ id }) => `/v1/labels/${id}`,
  })
  .delete({
    action: 'deleteLabel',
    path: ({ id }) => `/v1/labels/${id}`,
  })
  .put({
    action: 'updateConversationLabels',
    path: ({ id }) => `/v1/conversations/${id}/labels`,
  })
  .get({
    action: 'exportReactionsStats',
    path: '/v1/reactions/stats',
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/octet-stream',
    },
    requestConfig: {
      responseType: 'blob',
    },
  })
  .get({
    action: 'getAuditDeleted',
    property: 'auditDeleted',
    path: '/v1/audit/deleted',
  })
  .getStore()

rest.getters = {
  getField,
  getReactionsByName(state) {
    var reactions = {}
    for (var r of state.reactions) {
      reactions[r.name] = r
    }
    return reactions
  },
  getReactionsById(state) {
    var reactions = {}
    for (var r of state.reactions) {
      reactions[r.id] = r
    }
    return reactions
  },
  getActionsById(state) {
    var actions = {}
    for (var a of state.actions) {
      actions[a.id] = a
    }
    return actions
  },
  getProjectsById(state) {
    let projects = {}
    for (var p of state.projects) {
      projects[p.id] = p
    }
    return projects
  },
  getTransformedReactionCondition(state, getters) {
    return (condition) => {
      // first replace quoted string (accepts " and ' escapes \" works correctly)
      var extract_quoted_strings = new RegExp(/(["'])(?:(?=(\\?))\2.)*?\1/, 'g')
      var quoted_strings = []
      var replace_inner_quotes = condition.replace(extract_quoted_strings, (x) => {
        quoted_strings.push(x)
        return '__{' + (quoted_strings.length - 1) + '}__'
      })

      // if there is any regexp character in the string, escape it
      var escapeRegExpChars = function (s) {
        // eslint-disable-next-line
        return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
      }

      var reactions_names = state.reactions
        .map((x) => '' + escapeRegExpChars(x.name) + '')
        .sort(function (a, b) {
          return b.length - a.length
        })
        .join('|')

      // replace reaction names to {reaction_id}
      var r = new RegExp(reactions_names, 'g')
      var replaced_reactions = replace_inner_quotes.replace(r, (matchedName) => {
        if (!getters.getReactionsByName[matchedName]) {
          return ''
        }
        return '{' + getters.getReactionsByName[matchedName].id + '}'
      })

      var put_strings_back = replaced_reactions.replace(/__\{(\d+)\}__/g, (m, x) => {
        return quoted_strings[parseInt(x)]
      })

      // console.log(put_strings_back);
      return put_strings_back
    }
  },
  getFormattedReactionCondition(state, getters) {
    return (condition) => {
      if (!condition) {
        return ''
      }
      return condition.replace(/{(\d+)}/g, (_wholeMatch, matchedId) => {
        if (!getters.getReactionsById[matchedId]) {
          return 'undefined'
        }
        return getters.getReactionsById[matchedId].name
      })
    }
  },
  findUserById(state, getters) {
    return (id) => {
      let user = state.users.find((u) => u.id === id)
      return user ? user : { name: 'N/A (insufficient permissions)' }
    }
  },
  getActionsPathsMap(state) {
    return actionsPathsMap
  },
}
rest.mutations.updateField = updateField
rest.mutations.addIntent = (state, intent) => {
  state.intents.push(intent)
}
rest.namespaced = true

export default new Vuex.Store({
  // namespaced: true,
  modules: {
    layout,
    pam,
    rest,
    metadata,
  },
})
