<template>
  <div>
    <b-form @submit.prevent="loadPage" class="d-flex mb-1">
      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text> https:// </b-input-group-text>
        </b-input-group-prepend>
        <b-input type="text" v-model="url" />
      </b-input-group>
      <b-button class="btn btn-primary">Show</b-button>
    </b-form>
    <div class="alert alert-danger" v-for="error of errors">
      {{ error }}
    </div>
    <div class="pagesContainter">
      <iframe :src="iframeUrl" id="previewPage" ref="previewPage"></iframe>
      <iframe id="testPage" ref="testPage"> </iframe>
    </div>
    <small>Please note that the page in background server only as example of look.</small>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Loading from 'vue-loading-overlay'
import Conversation from '@/components/Conversation/Conversation'
import ConversationToTest from '@/components/Conversation/ConversationToTest'
import './ChatbotTest.scss'
import getChatbotPath from '@/core/chatbotPathCreator'

export default {
  name: 'ChatbotTest',
  components: {},
  computed: {
    ...mapFields('rest', ['settings']),
  },
  data() {
    return {
      url: 'chytrecallcentrum.cz',
      iframeUrl: 'https://www.plzen.eu',
      errors: [],
    }
  },
  methods: {
    loadPage() {
      this.iframeUrl = 'https://' + this.url
    },
    loadChatbot() {
      this.errors = []
      let iframeDocument = this.$refs['testPage'].contentWindow.document
      let scriptElement = iframeDocument.createElement('script')
      scriptElement.src = getChatbotPath(this.settings.chatbot_base_url, this.settings.id, 'dev', this.settings.chatbot_token)
      scriptElement.onerror = (e) => {
        this.errors.push(
          'Chatbot development bundle loading have failed. Please make sure that the settings were properly saved and deployed.'
        )
      }
      iframeDocument.querySelector('body').appendChild(scriptElement)
    },
  },
  mounted() {
    this.loadPage()
    if (this.settings.chatbot_base_url) {
      this.loadChatbot()
    }
  },
  watch: {
    settings: function () {
      if (this.settings.chatbot_base_url) {
        this.loadChatbot()
      }
    },
  },
}
</script>
