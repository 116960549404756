<template>
  <div>
    <div
      v-if="chatOpened"
      class="position-fixed rounded border float-right card box-shadow-3 flex-column"
      style="height: 500px; width: 400px; right: 40px; bottom: 70px; z-index: 1024"
      v-bind:style="maximalize ? 'height: 80%; width: 80%;' : 'height: 500px; width: 400px;'"
    >
      <div class="card-header bg-gray-300 py-1 px-2">
        <GlobalEvents @keydown.ctrl.77="maximalize = !maximalize" />

        <div class="d-flex">
          <div class="flex-grow-1">
            <h5 class="mb-0">Chat test</h5>
          </div>

          <b-form-select
            v-model="logLevel"
            :options="availableLogLevels"
            class="w-auto py-0 mr-2"
            style="font-size: 12px !important"
            size="sm"
          ></b-form-select>
          <div>
            <ConversationToTest :tracker="testChat.tracker" ref="conversation-to-test"> </ConversationToTest>

            <i class="mt-1 mr-2 fa fa-vial" @click="$refs['conversation-to-test'].openModal()" title="Convert to Test"> </i>
            <i class="mt-1 mr-2 fa fa-redo" @click="restartChat" title="Reload conversation"> </i>
            <i
              class="mt-1 mr-2 fa"
              :class="{ 'fa-expand-alt': !maximalize, 'fa-compress-alt': maximalize }"
              @click="maximalize = !maximalize"
              title="Maximalize: Ctrl + M"
            >
            </i>
            <i class="mt-1 fa fa-times" @click="chatOpened = !chatOpened" title="Close"> </i>
          </div>
        </div>
      </div>

      <div class="bg-gray-100 flex-grow-1 p-2 overflow-auto" id="chat" ref="chat-window">
        <div v-if="testChat['tracker'] != undefined">
          <Conversation
            :tracker="testChat.tracker"
            :level="logLevel"
            v-on:button="chatButton"
            v-on:upload="fileUpload"
            ref="chat-conversation"
            :interactive="true"
            :entitiesLoader="fetchEntityByName"
            :readUtter="hasReadUtters ? readUtter : null"
          />
        </div>

        <loading
          :active="loading"
          :is-full-page="false"
          background-color="#343a40"
          color="#f24516"
          :z-index="100000"
          :opacity="0.2"
          loader="dots"
          :width="30"
          :can-cancel="true"
        ></loading>
      </div>

      <div class="p-2 bg-gray-300">
        <div ref="chat-text-input" class="input-group" v-if="!conversationEnded">
          <input
            id="btn-input"
            autofocus
            type="text"
            class="form-control input-sm chat_input"
            placeholder="Write your message here..."
            ref="chat_input"
            v-on:keyup.enter="sendMessage"
          />
          <button class="btn btn-primary btn-sm" id="btn-chat" @click="sendMessage">
            <i class="fa fa-paper-plane fa-1x" aria-hidden="true"></i>
          </button>
        </div>

        <button
          v-if="conversationEnded"
          class="btn btn-primary btn-sm float-right"
          active
          id="btn-chat"
          title="Reload conversation"
          @click="restartChat"
        >
          <GlobalEvents @keydown.enter="restartChat" />Conversation ended &#x21bb;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapFields } from 'vuex-map-fields'
import Loading from 'vue-loading-overlay'
import Conversation from '@/components/Conversation/Conversation'
import ConversationToTest from '@/components/Conversation/ConversationToTest'

export default {
  name: 'Chat',
  components: { Loading, Conversation, ConversationToTest },
  computed: {
    ...mapFields('rest', ['chatOpened', 'testChat', 'availableLogLevels', 'settings', 'entities']),
    conversationEnded() {
      if (this.testChat == null || this.testChat.tracker == null) {
        return false
      }

      for (var c of this.testChat.tracker) {
        // termination actions
        if (c.type == 'action' && ['call_end', 'call_transfer'].includes(c.value)) {
          return true
        }
      }
      return false
    },
    hasPersistentTimeouts() {
      let setting = this.settings.chatbot_client_scss.split('\n').find((x) => x.includes('$persistent-timeouts'))
      if (setting && !setting.startsWith('/') && setting.includes('true')) {
        return true
      }
      return false
    },
    hasReadUtters() {
      let setting = this.settings.chatbot_client_scss.split('\n').find((x) => x.includes('$CHAT_READ_UTTERS_ENABLE'))
      if (setting && !setting.startsWith('/') && setting.includes('true')) {
        return true
      }
      return false
    },
    getReadUttersspeed() {
      let setting = this.settings.chatbot_client_scss.split('\n').find((x) => x.includes('$CHAT_READ_UTTERS_SPEED'))
      const match = setting.match(/\d+((\.|,)\d+)?/)
      if (!match) {
        return 1.0
      }
      return match && match[0]
    },
  },
  data() {
    return {
      chatId: null,
      loading: false,
      logLevel: 'warn',
      maximalize: false,
      chatTimeoutReactions: {},
      conversationToken: null,
      socket: null,
      conversationProjects: [this.axios.defaults.params.project_id],
      conversationEntities: {},
      audioBlob: null,
      audioOutput: new Audio(),
    }
  },
  methods: {
    initChat() {
      this.loading = true
      this.conversationToken = window.crypto.randomUUID()

      this.$store
        .dispatch('rest/initChat', {
          data: { get_meta: true },
          params: { conversation_token: this.conversationToken },
        })
        .then((x) => {
          this.chatId = x.data.conversation_id
          this.$refs['chat_input'].value = ''
          this.$refs['chat_input'].focus()

          this.checkTrackerIncrement(this.testChat.tracker)
          this.updateReactionsTimeout(this.testChat.tracker)
        })
        .catch(() => {
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Error!',
            text: 'Cannot init chat',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    chatButton(text) {
      this.$refs['chat_input'].value = text
      this.sendMessage()
    },
    sendMessage() {
      var msg = this.$refs['chat_input'].value
      if (!msg) {
        return
      }

      this.loading = true

      this.$store
        .dispatch('rest/postChatMessage', {
          params: { id: this.chatId, conversation_token: this.conversationToken },
          data: {
            msg: msg,
            last_ts: this.testChat.tracker.slice(-1)[0].timestamp,
            get_meta: true,
          },
        })
        .then((x) => {
          Vue.set(this.testChat, 'tracker', this.testChat.tracker.concat(x.data.tracker))
          this.chatId = x.data.conversation_id
          this.scrollMessages()
          this.checkTrackerIncrement(x.data.tracker)
          this.updateReactionsTimeout(x.data.tracker)
        })
        .catch(() => {
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Error!',
            text: 'Cannot send message',
          })
        })
        .finally(() => {
          this.loading = false
        })

      this.$refs['chat_input'].value = ''
    },
    restartChat() {
      this.testChat = {}
      this.chatId = null
      if (this.socket) {
        this.socket.close()
      }
      this.initChat()
    },
    updateReactionsTimeout(trackerIncrement) {
      // clear all timeouts
      for (let r in this.chatTimeoutReactions) {
        clearTimeout(this.chatTimeoutReactions[r])
      }

      let tracker
      if (this.hasPersistentTimeouts) {
        tracker = this.testChat.tracker // if has persistent timeouts, use the whole tracker
      } else {
        tracker = trackerIncrement // else use only the increment. eg. all timeouts will be reseted
      }

      for (let c of tracker) {
        if (c.type == 'action' && c.value == 'chat_set_timeout') {
          let reaction = c.args[0]
          let timeout = c.args[1]

          if (this.chatTimeoutReactions[reaction]) {
            clearTimeout(this.chatTimeoutReactions[reaction])
          }

          this.chatTimeoutReactions[reaction] = setTimeout(() => {
            this.$store
              .dispatch('rest/postChatTimeout', {
                params: { id: this.chatId, conversation_token: this.conversationToken },
                data: {
                  reaction: reaction,
                  last_ts: this.testChat.tracker.slice(-1)[0].timestamp,
                  get_meta: true,
                },
              })
              .then((x) => {
                Vue.set(this.testChat, 'tracker', this.testChat.tracker.concat(x.data.tracker))
                this.scrollMessages()
                this.updateReactionsTimeout(x.data.tracker)
              })
              .catch(() => {
                this.$notify({
                  group: 'app',
                  type: 'error',
                  title: 'Error!',
                  text: 'Cannot send timeout request',
                })
              })
              .finally(() => {
                this.loading = false
              })
          }, timeout * 1000)
        }

        if (c.type == 'action' && c.value == 'chat_clear_timeout') {
          let reaction = c.args[0]
          clearTimeout(this.chatTimeoutReactions[reaction])
        }
      }
    },
    scrollMessages() {
      this.$nextTick(() => {
        this.$refs['chat-conversation'].scrollDown()
      })
    },
    fileUpload(e, chatAttachmentIndex) {
      this.handleFileUpload(e, chatAttachmentIndex)
    },
    handleFileUpload(e, chatAttachmentIndex) {
      let formData = new FormData()
      let filenames = []
      let files = e.type === 'drop' ? e.dataTransfer.files : e.target.files
      for (var f of files) {
        filenames.push(f.name)
        formData.append('files', f, f.name)
      }

      formData.append('last_ts', this.testChat.tracker.slice(-1)[0].timestamp)
      formData.append('chat_attachment_index', chatAttachmentIndex)

      this.axios
        .put('/v1/chat/attachments', formData, {
          params: {
            id: this.chatId,
            conversation_token: this.conversationToken,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((x) => {
          Vue.set(this.testChat, 'tracker', this.testChat.tracker.concat(x.data.tracker))

          this.$notify({
            group: 'app',
            type: 'success',
            title: 'Successfully uploaded',
          })
        })
        .catch(() => {
          this.$notify({
            group: 'app',
            type: 'error',
            title: 'Error!',
            text: 'Cannot upload files!',
          })
        })
        .finally(() => {
          document.getElementById('upload').value = null
        })
    },
    checkTrackerIncrement(trackerIncrement) {
      for (let c of trackerIncrement) {
        if (c.type == 'action') {
          if (c.value == 'conversation_transfer') {
            this.conversationProjects.push(parseInt(c.args[0]))
          }
          if (c.value == 'chat_redirect_to_aws_connect') {
            this.redirectToAnotherSystem()
          }
          if (c.value == 'chat_show_text_input') {
            if (['true', 'yes', '1'].includes(c.args[0].toLowerCase())) {
              this.$refs['chat-text-input'].style.display = 'flex'
            } else {
              this.$refs['chat-text-input'].style.display = 'none'
            }
          }
        }
      }
    },
    redirectToAnotherSystem() {
      const baseURL = this.axios.defaults.baseURL.replace('http', 'ws')

      let url = new URL(baseURL + 'v1/chat/subscribe-integration-messages')
      url.searchParams.set('project_id', this.settings.id)
      url.searchParams.set('project_env', 'dev')
      url.searchParams.set('chat-token', this.settings.chatbot_token)
      url.searchParams.set('conversation_token', this.conversationToken)
      url.searchParams.set('id', this.chatId)

      const initWs = () => {
        this.socket = new WebSocket(url)

        this.socket.onmessage = (event) => {
          this.testChat.tracker.push(JSON.parse(event.data))
          this.scrollMessages()
          if (this.conversationEnded) {
            this.socket.close()
          }
        }

        this.socket.onclose = (event) => {
          if (!event.wasClean || event.code == 1001) {
            setTimeout(initWs(), 3000)
          }
        }

        window.addEventListener('beforeunload', () => {
          this.socket.send('disconnect')
        })
      }
      initWs()
    },
    async fetchEntityByName(name) {
      if (this.conversationEntities[name]) {
        return this.conversationEntities[name]
      }

      if (_.last(this.conversationProjects) == this.axios.defaults.params.project_id) {
        let e = _.find(this.entities, { name: name })
        if (!e) {
          return []
        }
        this.conversationEntities[name] = e.values.split('\n').map((x) => x.split(':')[0])
        return this.conversationEntities[name]
      }

      try {
        let res = await this.axios.get('/v1/entities', {
          params: { project_id: _.last(this.conversationProjects), ignore403: true },
        })
        let e = _.find(res.data, { name: name })

        if (!e) {
          this.conversationEntities[name] = ['Entity not found']
        } else {
          this.conversationEntities[name] = e.values.split('\n').map((x) => x.split(':')[0])
        }
      } catch (error) {
        this.conversationEntities[name] = ['N/A Insufficient permisssions']
      }

      return this.conversationEntities[name]
    },
    readUtter(utter) {
      this.axios
        .post(
          'v1/chat/read-utter?id=' + this.chatId + '&conversation_token=' + this.conversationToken,
          {
            utter: utter,
            last_ts: this.testChat.tracker.slice(-1)[0].timestamp,
          },
          { responseType: 'arraybuffer' }
        )
        .then((response) => {
          let blob = new Blob([response.data], {
            type: 'audio/wav',
          })

          if (!this.audioOutput.paused) {
            this.audioOutput.pause()
            return
          }
          this.audioOutput.src = URL.createObjectURL(blob)
          this.audioOutput.playbackRate = this.getReadUttersspeed
          this.audioOutput.play()
        })
    },
  },
  watch: {
    chatOpened: function (newVal, oldVal) {
      if (newVal == true) {
        this.restartChat()
      }
    },
    logLevel: function (newVal, oldVal) {
      this.$nextTick(() => {
        this.$refs['chat-conversation'].scrollDown()
      })
    },
  },
}
</script>

<style src="../Conversation/Conversation.scss" lang="scss" />
